import React from 'react';
import { PortableText } from '@portabletext/react';
import { GatsbyImage } from 'gatsby-plugin-image';

import CTAButtons from '../cta-buttons';

export const portableTextHeading = {
  block: {
    normal: ({ children }) => <span>{children}</span>,
  },
  marks: {
    highlight: ({ children, value }) => (
      <span className="title-hl">{children}</span>
    ),
    link: ({ children, value }) => {
      const rel = !value.href.startsWith('/')
        ? 'noreferrer noopener'
        : undefined;
      return (
        <a href={value.href} rel={rel}>
          {children}
        </a>
      );
    },
  },
};

const portableText = {
  block: {
    normal: ({ children }) => (
      <p className="my-4 text-base font-normal text-black">{children}</p>
    ),
  },
  list: {
    number: ({ children }) => (
      <ol className="my-6 list-outside list-decimal px-4 font-normal">
        {children}
      </ol>
    ),
    bullet: ({ children }) => (
      <ul className="my-6 list-inside list-disc">{children}</ul>
    ),
  },
  marks: {
    strong: ({ children }) => <span className="font-bold">{children}</span>,
    link: ({ children, value }) => {
      const rel = !value.href.startsWith('/')
        ? 'noreferrer noopener'
        : undefined;
      return (
        <a
          href={value.href}
          rel={rel}
          className="text-blue-800 underline transition-all duration-300 hover:text-purply-blue"
        >
          {children}
        </a>
      );
    },
  },
};

const portableSmallText = {
  block: {
    normal: ({ children }) => (
      <p className="text-xs font-normal text-black/60">{children}</p>
    ),
  },
};

const AreasSectionOne = ({ sectionData }) => {
  return (
    <section className="mx-auto my-8 w-full max-w-[90rem] px-5 lg:px-20">
      {/* Title */}
      <h1 className="my-8 text-[34px] leading-tight text-black md:my-16 md:block md:text-5xl lg:text-[3.5rem]">
        {sectionData.title}
      </h1>

      {/* Content */}
      <div className="my-4 border-t border-black/20 pb-4 pt-8 lg:pt-16">
        {/* Subtitle and description */}
        <div className="flex flex-col gap-8 md:flex-row">
          <h3 className="text-3xl font-medium leading-tight text-smoky-black md:w-[50%] md:text-4xl">
            <PortableText
              value={sectionData._rawSubtitle}
              components={portableTextHeading as any}
            />
          </h3>
          <div className="-mt-4 w-full md:w-1/2">
            <PortableText
              value={sectionData._rawDescription}
              components={portableText as any}
            />
          </div>
        </div>

        {/* Images */}
        <div className="my-8 flex flex-col gap-14 md:flex-row md:gap-8 lg:my-16 lg:gap-10">
          {sectionData.images.map((imageData) => (
            <div className="w-full md:w-1/3" key={imageData.title}>
              <h5 className="text-lg font-medium text-black lg:my-2 lg:text-2xl">
                {imageData.title}
              </h5>
              <div className="w-full px-0 py-2">
                <GatsbyImage
                  image={imageData.image.asset.gatsbyImageData}
                  alt={imageData.imageAlt}
                  imgClassName="w-full h-full object-cover -p-2"
                />
              </div>
              <p>
                <PortableText
                  value={imageData._rawInfoText}
                  components={portableSmallText as any}
                />
              </p>
            </div>
          ))}
        </div>

        {/* Info Sections */}
        <div className="my-8 flex flex-col gap-8 md:flex-row">
          {sectionData.infoSections.map((infoSection, index) => (
            <div key={infoSection.title} className="w-full md:w-1/2">
              <h5 className="my-4 text-lg font-medium text-black lg:my-2 lg:text-2xl">
                {infoSection.title}
              </h5>
              <PortableText
                value={infoSection._rawText}
                components={portableText as any}
              />
            </div>
          ))}
        </div>

        {/* Summary */}
        <div className="my-8 rounded-xl bg-[#F8F9FF] p-8 lg:p-16 lg:px-16">
          <h4 className="mb-4 text-xl font-normal leading-6 text-black lg:mb-12 lg:text-[32px] lg:leading-10">
            <PortableText
              value={sectionData._rawSummaryTitle}
              components={portableTextHeading as any}
            />
          </h4>

          <PortableText
            value={sectionData._rawSummary}
            components={portableText as any}
          />
        </div>

        {/* CTAs */}
        <CTAButtons
          title={sectionData._rawCtaTitle}
          primaryBtn={sectionData.ctaLinks[0]}
          secondaryBtn={sectionData.ctaLinks[1]}
        />
      </div>
    </section>
  );
};

export default AreasSectionOne;
