import React from 'react';
import { PortableText } from '@portabletext/react';

import Section from '../../common/section';
import AreasSections from './areas-sections';

const portableHeading = {
  block: {
    normal: ({ children }) => (
      <h2 className="line text-3xl leading-[33px] text-smoky-black md:w-1/2 md:text-4xl lg:text-[44px] lg:leading-[48px]">
        {children}
      </h2>
    ),
  },
};

const portableText = {
  block: {
    normal: ({ children }) => (
      <p className="mb-3 text-base leading-6 text-black md:w-1/2">{children}</p>
    ),
  },
};

const AreasSection = ({ areasData }) => {
  return (
    <div className="pt-10 lg:pt-32">
      <div className="mx-auto flex w-full max-w-[90rem] flex-col justify-between gap-4 px-5 md:flex-row md:gap-8 lg:px-20">
        <PortableText
          value={areasData._rawTitle}
          components={portableHeading as any}
        />
        <PortableText
          value={areasData._rawText}
          components={portableText as any}
        />
      </div>

      <AreasSections sections={areasData.sections} />
    </div>
  );
};

export default AreasSection;
