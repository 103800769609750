import React, { useState } from 'react';
import { InView, useInView } from 'react-intersection-observer';

import Navbar from './navbar';
import SectionOne from './section-one';
import SectionTwo from './section-two';
import SectionThree from './section-three';
import SectionFour from './section-four';
import SectionFive from './section-five';

const AreasSections = ({ sections }) => {
  // navbar items with title and id
  const navbarItems = sections.map((section) => ({
    title: section.navTitle,
    id: section.navTitle.toLowerCase().replace(/\s/g, '-'),
  }));

  const [visibleSection, setVisibleSection] = useState(navbarItems[0].id);

  const { ref } = useInView({
    threshold: 0.3,
  });

  const setInView = (inView, entry) => {
    if (inView) {
      setVisibleSection(entry.target.getAttribute('id'));
    }
  };

  // sections with render element
  const sectionsWithEl = sections.map((section) => {
    switch (section._type) {
      case 'areasOfResearchSectionOne':
        return <SectionOne key={section._key} sectionData={section} />;
      case 'areasOfResearchSectionTwo':
        return <SectionTwo key={section._key} sectionData={section} />;
      case 'areasOfResearchSectionThree':
        return <SectionThree key={section._key} sectionData={section} />;
      case 'areasOfResearchSectionFour':
        return <SectionFour key={section._key} sectionData={section} />;
      case 'areasOfResearchSectionFive':
        return <SectionFive key={section._key} sectionData={section} />;
      default:
        return null;
    }
  });

  return (
    <div className="mt-16">
      <div className="sticky top-[88px] z-30 mx-auto w-full max-w-[90rem] px-5 lg:top-[7rem] lg:px-20">
        <Navbar visibleSection={visibleSection} items={navbarItems} />
      </div>
      <div ref={ref}>
        {sections.map((section, index) => (
          <InView onChange={setInView} key={section.title}>
            {({ ref }) => {
              return (
                <div id={navbarItems[index].id} ref={ref}>
                  {sectionsWithEl[index]}
                </div>
              );
            }}
          </InView>
        ))}
      </div>
    </div>
  );
};

export default AreasSections;
