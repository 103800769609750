import React from 'react';
import { GatsbyImage } from 'gatsby-plugin-image';
import { PortableText } from '@portabletext/react';

import CTAButtons from '../cta-buttons';

export const portableHeading = {
  block: {
    normal: ({ children }) => <span>{children}</span>,
  },
  marks: {
    highlight: ({ children, value }) => (
      <span className="title-hl">{children}</span>
    ),
    link: ({ children, value }) => {
      const rel = !value.href.startsWith('/')
        ? 'noreferrer noopener'
        : undefined;
      return (
        <a href={value.href} rel={rel}>
          {children}
        </a>
      );
    },
  },
};

const portableTextHeading = {
  block: {
    normal: ({ children }) => <span>{children}</span>,
  },
  marks: {
    highlight: ({ children, value }) => (
      <span className="title-hl">{children}</span>
    ),
    link: ({ children, value }) => {
      const rel = !value.href.startsWith('/')
        ? 'noreferrer noopener'
        : undefined;
      return (
        <a href={value.href} rel={rel}>
          {children}
        </a>
      );
    },
  },
};

const portableText = {
  block: {
    normal: ({ children }) => (
      <p className="my-4 text-base font-normal text-black">{children}</p>
    ),
  },
  list: {
    number: ({ children }) => (
      <ol className="my-6 list-outside list-decimal px-4 font-normal">
        {children}
      </ol>
    ),
    bullet: ({ children }) => (
      <ul className="my-6 list-inside list-disc">{children}</ul>
    ),
  },
  marks: {
    strong: ({ children }) => <span className="font-bold">{children}</span>,
    link: ({ children, value }) => {
      const rel = !value.href.startsWith('/')
        ? 'noreferrer noopener'
        : undefined;
      return (
        <a
          href={value.href}
          rel={rel}
          className="text-blue-800 underline transition-all duration-300 hover:text-purply-blue"
        >
          {children}
        </a>
      );
    },
  },
};

const AreasSectionTwo = ({ sectionData }) => {
  const {
    title,
    _rawSubtitle,
    _rawDescription,
    _rawText,
    mobileImage,
    desktopImage,
    imageAlt,
    chartsMobileImage,
    chartsDesktopImage,
    chartsImageAlt,
    summary,
    _rawCtaTitle,
    ctaLinks,
  } = sectionData;

  return (
    <section className="mx-auto my-8 w-full max-w-[90rem] px-5 lg:px-20">
      {/* Title */}
      <h1 className="my-8 text-[34px] leading-tight text-black md:my-16 md:block md:text-5xl lg:text-[3.5rem]">
        {title}
      </h1>

      {/* Content */}
      <div className="my-4 border-t border-black/20 pb-4 pt-8 lg:pt-16">
        {/* Subtitle and description */}
        <div className="flex flex-col gap-8 md:flex-row">
          <h3 className="text-3xl font-normal leading-tight text-smoky-black md:w-[50%] md:text-4xl">
            <PortableText
              value={_rawSubtitle}
              components={portableHeading as any}
            />
          </h3>
          <div className="-mt-4 w-full md:w-1/2">
            <PortableText
              value={_rawDescription}
              components={portableText as any}
            />
          </div>
        </div>

        {/* Text & Image Section */}
        <div className="mt-4 rounded-t-lg border-l border-r border-t border-hi-3 p-8 md:my-8 md:rounded-lg md:border lg:my-12">
          <p className="w-full text-base font-normal leading-6 text-black">
            <PortableText value={_rawText} />
          </p>
          {/* desktop image */}
          <div className="hidden md:block">
            <GatsbyImage
              image={desktopImage.asset.gatsbyImageData}
              alt={imageAlt}
              className="mt-8 hidden md:block"
            />
          </div>
        </div>
        {/* mobile image */}
        <div className="block md:hidden">
          <GatsbyImage
            image={mobileImage.asset.gatsbyImageData}
            alt={imageAlt}
            className="h-full w-full rounded-b-lg object-cover md:hidden"
          />
        </div>

        {/* Mobile chart image */}
        <div className="block md:hidden">
          <GatsbyImage
            image={chartsMobileImage.asset.gatsbyImageData}
            alt={chartsImageAlt}
            className="my-4 w-full md:hidden"
          />
        </div>

        {/* Desktop chart image */}
        <div className="hidden md:block">
          <GatsbyImage
            image={chartsDesktopImage.asset.gatsbyImageData}
            alt={chartsImageAlt}
            className="hidden w-full object-contain md:block"
          />
        </div>

        {/* Summary */}
        <div className="my-4 rounded-xl bg-[#F8F9FF] p-4 md:my-8 md:p-8 lg:my-16 lg:p-16 lg:px-16">
          <div className="mb-4 flex flex-col gap-8 md:mb-8 md:flex-row lg:mb-16">
            <h4 className="mb-4 w-full text-xl font-normal leading-6 text-black md:w-1/2 lg:mb-12 lg:text-[32px] lg:leading-10">
              <PortableText
                value={summary._rawTitle}
                components={portableTextHeading as any}
              />
            </h4>
            <div className="-mt-4 w-full md:w-1/2">
              <PortableText
                value={summary._rawText}
                components={portableText as any}
              />
            </div>
          </div>
          {/* mobile image */}
          <div className="block md:hidden">
            <GatsbyImage
              image={summary.mobileImage.asset.gatsbyImageData}
              alt={summary.imageAlt}
              className="my-4 w-full md:hidden"
            />
          </div>
          {/* desktop image */}
          <div className="hidden md:block">
            <GatsbyImage
              image={summary.desktopImage.asset.gatsbyImageData}
              alt={summary.imageAlt}
              className="hidden w-full object-contain md:block"
            />
          </div>
        </div>

        {/* CTAs */}
        <CTAButtons
          title={_rawCtaTitle}
          primaryBtn={ctaLinks[0]}
          secondaryBtn={ctaLinks[1]}
        />
      </div>
    </section>
  );
};

export default AreasSectionTwo;
