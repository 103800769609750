import React, { useState, useEffect } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { GatsbyImage } from 'gatsby-plugin-image';

export const portableTextHeading = {
  block: {
    normal: ({ children }) => <span>{children}</span>,
  },
  marks: {
    highlight: ({ children, value }) => (
      <span className="title-hl">{children}</span>
    ),
    link: ({ children, value }) => {
      const rel = !value.href.startsWith('/')
        ? 'noreferrer noopener'
        : undefined;
      return (
        <a href={value.href} rel={rel}>
          {children}
        </a>
      );
    },
  },
};

const variants = {
  enter: (direction) => ({
    x: direction > 0 ? 1000 : -1000,
    opacity: 0,
  }),
  center: {
    x: 0,
    opacity: 1,
  },
  exit: (direction) => ({
    x: direction < 0 ? 1000 : -1000,
    opacity: 0,
  }),
};

const Carousel = ({ carouselImages, page, direction, setPage }) => {
  const paginate = (newDirection) => {
    setPage([page + newDirection, newDirection]);
  };

  useEffect(() => {
    const slideInterval = setInterval(() => {
      paginate(1);
    }, 4000);

    return () => clearInterval(slideInterval);
  }, [page]);

  const currentIndex = page % carouselImages.length;

  return (
    <div className="flex flex-col">
      <div className="relative mb-3 h-[260px] w-full overflow-hidden sm:h-[250px] md:h-[375px] lg:h-[450px] xl:h-[550px] 2xl:h-[625px]">
        <AnimatePresence initial={false} custom={direction}>
          <motion.div
            key={page}
            custom={direction}
            variants={variants}
            initial="enter"
            animate="center"
            exit="exit"
            transition={{
              x: { type: 'spring', stiffness: 300, damping: 30 },
              opacity: { duration: 0.3 },
            }}
            className={`absolute flex h-full w-full items-center justify-center`}
          >
            <a
              href={carouselImages[currentIndex].link}
              target="_blank"
              rel="noopener noreferrer"
            >
              <GatsbyImage
                image={carouselImages[currentIndex].image.asset.gatsbyImageData}
                alt={carouselImages[currentIndex].imageAlt}
                imgClassName="w-full h-fit object-contain"
              />
            </a>
          </motion.div>
        </AnimatePresence>
      </div>

      {/* controls */}
      <div className="flex flex-row justify-between gap-2">
        {carouselImages.map((_, i) => (
          <button
            key={i}
            onClick={() => paginate(i - currentIndex)}
            className={`h-2 flex-grow rounded text-white ${i === currentIndex ? 'bg-primary-600' : 'bg-white/10'}`}
          />
        ))}
      </div>
    </div>
  );
};

const HeroCarouselSection = ({ carouselImages }) => {
  const [[page, direction], setPage] = useState([0, 0]);

  const currentIndex = page % carouselImages.length;

  return (
    <div className="pt-4sm:h-[250px] relative z-20 flex w-full flex-col justify-between rounded-lg border border-white/20 px-4 pb-4 md:h-[375px] lg:h-[450px] xl:h-[550px] 2xl:h-[625px]">
      {/* text section */}
      {/* <div className="mb-2 flex flex-col p-4">
        <h3 className="-ml-1 text-2xl font-normal text-white md:text-3xl lg:text-4xl">
          <PortableText
            value={carouselImages[currentIndex]._rawTitle}
            components={portableTextHeading as any}
          />
        </h3>
        <div className="my-2 text-base font-light text-white/80 lg:text-lg">
          <PortableText value={carouselImages[currentIndex]._rawSubtitle} />
        </div>
      </div> */}

      <Carousel
        carouselImages={carouselImages}
        page={page}
        direction={direction}
        setPage={setPage}
      />
    </div>
  );
};

export default HeroCarouselSection;
