import React from 'react';
import { PortableText } from '@portabletext/react';
import { Link } from 'gatsby';
import { FiChevronRight } from 'react-icons/fi';

export const portableHeading = {
  block: {
    normal: ({ children }) => <span>{children}</span>,
  },
  marks: {
    highlight: ({ children, value }) => (
      <span className="title-hl">{children}</span>
    ),
    link: ({ children, value }) => {
      const rel = !value.href.startsWith('/')
        ? 'noreferrer noopener'
        : undefined;
      return (
        <a href={value.href} rel={rel}>
          {children}
        </a>
      );
    },
  },
};

const CTALink = ({ link, className }) => {
  if (link._type === 'internalLink') {
    return (
      <Link to={`/${link.slug.current}`} className={className}>
        {link.label}
        <FiChevronRight className="text-2xl" />
      </Link>
    );
  }

  if (link._type === 'externalLink') {
    return (
      <a
        href={link.url}
        target="_blank"
        rel="noopener noreferrer"
        className={className}
      >
        {link.label}
        <FiChevronRight className="text-2xl" />
      </a>
    );
  }
};

const portableText = {
  block: {
    normal: ({ children }) => (
      <p className="my-4 text-base font-normal text-black">{children}</p>
    ),
  },
  list: {
    number: ({ children }) => (
      <ol className="my-4 list-outside list-decimal px-4 font-normal">
        {children}
      </ol>
    ),
    bullet: ({ children }) => (
      <ul className="my-6 list-inside list-disc">{children}</ul>
    ),
  },
  marks: {
    strong: ({ children }) => <span className="font-bold">{children}</span>,
    link: ({ children, value }) => {
      const rel = !value.href.startsWith('/')
        ? 'noreferrer noopener'
        : undefined;
      return (
        <a
          href={value.href}
          rel={rel}
          className="text-blue-800 underline transition-all duration-300 hover:text-purply-blue"
        >
          {children}
        </a>
      );
    },
  },
};

const SectionFive = ({ sectionData }) => {
  const { title, _rawText, sections } = sectionData;

  return (
    <div className="bg-violet-400 py-8 lg:py-28">
      <section className="mx-auto my-4 w-full max-w-[90rem] px-5 lg:px-20">
        <div className="flex flex-col gap-8">
          <div>
            {/* TItle */}
            <h1 className="my-6 text-[34px] leading-tight text-black md:block md:text-5xl lg:text-[3.5rem]">
              {title}
            </h1>
            {/* Text */}
            <PortableText value={_rawText} components={portableText as any} />
          </div>

          {/* sections */}
          <div className="flex flex-col border-t border-t-smoky-black/20 py-8 md:py-12 lg:py-16">
            {sections.map((section) => (
              <div
                key={section.title}
                className="flex flex-col gap-4 py-6 lg:py-12"
              >
                <p className="text-base font-normal text-black">
                  {section.title}
                </p>
                <div className="grid grid-cols-1 gap-8 md:grid-cols-3">
                  {section.links.map((link) => (
                    <CTALink
                      link={link}
                      key={link.label}
                      className="flex w-full flex-shrink-0 items-center justify-between rounded-lg border border-smoky-black/20 bg-white px-4 py-4 text-base font-semibold text-black hover:opacity-75 md:text-xl"
                    />
                  ))}
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>
    </div>
  );
};

export default SectionFive;
