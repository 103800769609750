import React from 'react';

type SectionProps = {
  children: React.ReactNode;
  className?: string;
};

const Section = ({ children, className }: SectionProps) => (
  <section
    className={`mx-auto max-w-[90rem] px-5 py-10 lg:px-20 lg:py-32 ${className}`}
  >
    {children}
  </section>
);

export default Section;
